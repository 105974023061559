import * as React from "react"
import { graphql } from "gatsby"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

// Update the path to your Link Resolver
import Layout from "@components/layout"
import Seo from "@components/seo"
import Page404 from "@templates/404"

// Update the path to your Pages and Templates
import { repositoryConfigs } from "../utils/preview"

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `withPrismicUnpublishedPreview`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby#3.-404-not-found-page
 */

const NotFoundPage = ({ data = {} }) => {
  const navigation = data.prismicNavigation || {}
  const pageNotFound = data.prismicPageNotFound || {}
  const doc = pageNotFound.data || {}
  const articles = data.articles || {}

  return (
    <Layout navigation={navigation}>
      <Seo title={"404"} description={doc.title} />
      <Page404 doc={doc} articles={articles.nodes || []} />
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPage($lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
    prismicPageNotFound(lang: { eq: $lang }) {
      data {
        go_back
        articles_title
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
        title
      }
    }
    articles: allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: data___date }
      limit: 3
    ) {
      nodes {
        ...BlogpostCard
      }
    }
  }
`

export default withPrismicUnpublishedPreview(NotFoundPage, repositoryConfigs)
