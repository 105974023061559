import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Article from "@templates/blog-components/articles-grid/article"

import styled from "styled-components"
import {
  mediaQueries,
  Container,
  T2,
  T5,
  ButtonBlue,
  Grid,
  GridItem,
} from "@styles"

const Image = styled.div`
  margin: 0 0 24px;
  @media ${mediaQueries.preMedium} {
    zoom: 0.75;
  }
`

const Page = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI4NzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzNTAuMjgtNzk1LjY1NUwtMTI2LjA0My01ODYuMDgyLTI2LjU4OSA1ODkuMDdjMTQuNjg1IDE3My41MTEgMTY3LjI0NyAzMDIuMjY1IDM0MC43NTggMjg3LjU4TDIxNjIuMTUgNzIwLjI1M2MxNzMuNTEtMTQuNjg0IDMwMi4yNy0xNjcuMjQ2IDI4Ny41OC0zNDAuNzU3bC05OS40NS0xMTc1LjE1MXoiIGZpbGw9IiNGRUZBRjEiLz48L3N2Zz4=);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjU3NyIgaGVpZ2h0PSI4NzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI0NzYuMzIzLTc5NUwwLTU4NS40MjcgOTkuNDU1IDU4OS43MjVDMTE0LjEzOSA3NjMuMjM2IDI2Ni43IDg5MS45OSA0NDAuMjEyIDg3Ny4zMDVsMTg0Ny45ODEtMTU2LjM5N2MxNzMuNTEtMTQuNjg0IDMwMi4yNy0xNjcuMjQ2IDI4Ny41OC0zNDAuNzU3TDI0NzYuMzIzLTc5NXoiIGZpbGw9IiNGRUZBRjEiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  background-position: top right -40px;
  background-size: 1840px;

  padding: 104px 0 0;
  @media ${mediaQueries.medium} {
    padding: 154px 0;
    background-position: top left -100px;
    background-size: 2577px;
  }

  article {
    border: 1px solid ${props => props.theme.colors.grey25};
  }
`

const Header = styled.header`
  padding-bottom: 104px;
`

const Page404 = ({ doc, lang = "fr-fr", articles = [] }) => {
  const image = getImage(doc.image)
  return (
    <Page>
      <Container>
        <Header>
          <Image>
            <GatsbyImage
              image={image}
              alt={doc.title}
              sizes={`${image.width}px`}
            />
          </Image>
          <T2 as="h1" mb={6} pre>
            {doc.title}
          </T2>
          <ButtonBlue
            as="a"
            href={lang === "fr-fr" ? "/" : `/${lang.substr(0, 2)}/`}
          >
            <span>{doc.go_back}</span>
          </ButtonBlue>
        </Header>

        <section>
          <T5 as="h2" mb={6}>
            {doc.articles_title}
          </T5>

          <Grid>
            {articles.map(article => (
              <GridItem key={article.id} col={{ md: 4, lg: 3 }} mb={3}>
                <Article {...article} />
              </GridItem>
            ))}
          </Grid>
        </section>
      </Container>
    </Page>
  )
}

export default Page404
